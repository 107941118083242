import React from "react"
import { Box, useColorModeValue, useColorMode } from "@chakra-ui/react"
import BlogHeader from "~components/blog/blog-header"
import img from "~images/Backgrounds/cypher-blog-image.jpeg"
import img2 from "~images/Backgrounds/cypher-blog-image-two.jpeg"
import img3 from "~images/Backgrounds/cypher-blog-image-three.jpeg"

const BlogPage = () => {
  const bgColor = useColorModeValue("white", "#171F2B")
  const { colorMode } = useColorMode()

  return (
    <Box bgColor={bgColor} w={"100%"} margin={0}>
      <BlogHeader
        imgSrc={`${img}`}
        blogTitle={
          colorMode === "light"
            ? "Some intriguing title about a guy who got rich with crypto in Feburary"
            : "This is definitely not what you think it is"
        }
      />

      <BlogHeader
        imgSrc={`${img2}`}
        blogTitle={"Cryptocurrencies: A beginner's guide "}
      />
      <BlogHeader
        imgSrc={`${img3}`}
        blogTitle={"Why you should be investing in crypot now"}
      />
    </Box>
  )
}

export default BlogPage
