import React from "react"
import {
  Box,
  Flex,
  useColorModeValue,
  Image,
  Text,
  Link,
} from "@chakra-ui/react"

const BlogHeader: React.FC<{ imgSrc: string; blogTitle: string }> = ({
  imgSrc,
  blogTitle,
}) => {
  const textColor = useColorModeValue("#171f2b", "white")

  return (
    <Flex
      p={10}
      direction={["column", "column", "row"]}
      justifyContent={"space-between"}
      w={"100%"}
      margin={0}
    >
      <Box w={["100%", "100%", "55%", "60%"]} position="relative">
        {" "}
        <Image
          src={imgSrc}
          w={["330", "750px", "580px", "750px"]}
          h={["220", "500px", "387px", "500px"]}
          style={{ perspective: "700px" }}
        ></Image>{" "}
      </Box>
      <Flex w={["100%", "100%", "40%"]} direction={"column"}>
        {" "}
        <Text fontSize={["md", "xl"]} color={textColor} fontWeight={500}>
          {blogTitle}
        </Text>{" "}
        <Link>
          <Text color={textColor} textDecoration={"underline"} pt={5}>
            Read More
          </Text>
        </Link>
      </Flex>
    </Flex>
  )
}

export default BlogHeader
